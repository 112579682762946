/*
/*
=========================================================
* Snuggle Site v1.0 - Information in Gallery
=========================================================
 =========================================================
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import ImageCard from "examples/Cards/BlogCards/ImageCard";

// Video Player imports
import React from 'react'
import ReactPlayer from 'react-player'

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing="auto" alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="space-between">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/CraigClassyJoins_Original.JPG"
                    title="Craig Classy"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/StephenJoins_Original.JPG"
                    title="SH"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/JayJoins_Original.JPG"
                    title="JJ"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ReactPlayer url='https://streamable.com/4h81mi' />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_9887.JPG"
                    title="MILK"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/JimJoins_Original.JPG"
                    title="jim"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_9886.JPG"
                    title="swag"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_9883.PNG"
                    title="pimp"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_9307.PNG"
                    title="high"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_9651.JPG"
                    title="doughy"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_9361.JPG"
                    title="bean"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ReactPlayer url='https://streamable.com/l0riv5' />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_9228.JPG"
                    title="huggy"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_8376.PNG"
                    title="thucc"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_9160.JPG"
                    title="bleed"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_8363.PNG"
                    title="salt"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_8089.jpeg"
                    title="my lord"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_8229.JPG"
                    title="snock"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_8227.JPG"
                    title="snock 2 electric snockaloo"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ReactPlayer url='https://streamable.com/05d57f' />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_8131.JPG"
                    title="hot"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_7995.JPG"
                    title="hotter"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_7897.JPG"
                    title="baller"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_7622.PNG"
                    title="icon"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_6471.PNG"
                    title="poggers"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_6663.jpeg"
                    title="wiener"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_7453.PNG"
                    title="twincest"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ReactPlayer url='https://streamable.com/uxtplc' />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_7407.PNG"
                    title="bus"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_5884.JPG"
                    title="sloop"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_5845.JPG"
                    title="mexico"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_5713.JPG"
                    title="overcompensate"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_5657.JPG"
                    title="tool"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_5645.PNG"
                    title="cumming"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_5618.JPG"
                    title="cheez"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_5632.JPG"
                    title="hunk"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_5501.jpeg"
                    title="nirvana"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ReactPlayer url='https://streamable.com/kwa288' />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_5442.PNG"
                    title="giggle"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_5369.JPG"
                    title="idk who that is"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_5159.jpeg"
                    title="fetish"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_5072.JPG"
                    title="bieber fever"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_5044.PNG"
                    title="hippo wiener"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_5041.jpeg"
                    title="farewell"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_4933.JPG"
                    title="hangin"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_4932.PNG"
                    title="pumpkin nips"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_4929.JPG"
                    title="when you're sus"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ReactPlayer url='https://streamable.com/hsjuxs' />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_4928.JPG"
                    title="handsome"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_4927.JPG"
                    title="on the spectrum"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_4926.JPG"
                    title="cuddles"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_4818.JPG"
                    title="angwy"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_4814.JPG"
                    title="don't even know"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_4083.JPG"
                    title="adorable"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_3987.PNG"
                    title="bussin"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_4035.JPG"
                    title="virgin"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_3878.PNG"
                    title="virgin 2"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ReactPlayer url='https://streamable.com/ged9f1' />
                </MKBox>
              </Grid>           
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_3963.JPG"
                    title="princess"
                    description=""
                  />
                </MKBox>
              </Grid><Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_4018.JPG"
                    title="herobrine"
                    description=""
                  />
                </MKBox>
              </Grid><Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_3799.JPG"
                    title="sword"
                    description=""
                  />
                </MKBox>
              </Grid><Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_3866.PNG"
                    title="on the clock"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_3611.jpeg"
                    title="doody"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_3339.JPG"
                    title="lucas appearance"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_3285.JPG"
                    title="eggy"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ReactPlayer url='https://streamable.com/ka5t4p' />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_3267.JPG"
                    title="cuties"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_3220.JPG"
                    title="car thumb"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_3228.JPG"
                    title="gun"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_3217.JPG"
                    title="car butt"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_3162.JPG"
                    title="weeny face"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_3123.PNG"
                    title="embrace"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_3138.JPG"
                    title="squad"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ReactPlayer url='https://streamable.com/ni89df' />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_3112.JPG"
                    title="hollandaise"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_3104.JPG"
                    title="squat"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_3122.JPG"
                    title="run"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_3065.JPG"
                    title="pain"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_3064.JPG"
                    title="pain 2"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_3060.JPG"
                    title="eyes up"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_2986.PNG"
                    title="crush"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_2950.JPEG"
                    title="me mostly"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_2949.JPEG"
                    title="snow"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ReactPlayer url='https://streamable.com/r57o67' />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_2948.JPEG"
                    title="snow 2"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_2403.JPG"
                    title="fall down"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_2932.PNG"
                    title="nips"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_2947.JPEG"
                    title="bday"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_2946.JPEG"
                    title="joe and jim"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_2392.JPG"
                    title="stay up"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_2223.JPG"
                    title="chiken"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_2169.JPG"
                    title="no comment lmao"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_2001.PNG"
                    title="ralph and marshall"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_2061.JPEG"
                    title="prodigy"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_1849.JPG"
                    title="cry"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ReactPlayer url='https://streamable.com/dq4m1n' />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_1848.JPG"
                    title="cry 2"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_1338.jpeg"
                    title="cymballic"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_1632.JPG"
                    title="mr hands"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_1847.JPG"
                    title="cry 3"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_0880.JPG"
                    title="ham"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_1159.JPG"
                    title="cringe"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_1119.JPG"
                    title="virgin 3"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ReactPlayer url='https://streamable.com/wemalj' />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_0434.JPG"
                    title="poindexter"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/IMG_0429-1.JPEG"
                    title="mountain"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/62414252705__88195880-E520-4EDF-BC20-2A8051BBB7B2.JPG"
                    title="bday 2"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/66943323383__F8B6B9CA-F831-47B3-92C6-57589FE82BC0.jpeg"
                    title="me and sam"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/DrewskiJoins_Original.JPG"
                    title="tp"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/65764658378__7A01FEB1-FB7A-4201-9DD9-FA83B920A473.jpeg"
                    title="johnny bravo"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ImageCard
                    image="https://imagedump.imgix.net/65956394339__A1B077C6-3D57-42CC-8568-8066393275B9.jpeg"
                    title="schleep"
                    description=""
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <ReactPlayer url='https://streamable.com/9ewd1v' />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
